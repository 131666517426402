import React from 'react';
import Icon from '../components/Icon';
import Steps from '../components/Steps/Steps';
import TabPanel from '../components/Tabs/TabPanel';
import PaymentGatewaySlider from '../components/Sliders/PaymentGatewaySlider/PaymentGatewaySlider';

export const heroCheckList = [
  {
    id: 0,
    listItem: '120+ payment modes',
  },
  {
    id: 1,
    listItem: 'International payments from day 1',
  },
  {
    id: 2,
    listItem: 'Best success rates',
  },
];
const logoData = [
  { id: 0, type: 'upi' },
  { id: 1, type: 'phonepe' },
  { id: 2, type: 'paytm' },
  { id: 3, type: 'visa' },
  { id: 4, type: 'mastercard' },

  { id: 5, type: 'rupay' },
  { id: 6, type: 'maestro' },
  { id: 7, type: 'amazonpaylogo' },
  { id: 8, type: 'gpayLogo' },
  { id: 9, type: 'olamoneym', padding: 12 },

  { id: 10, type: 'zestmoneyPng', padding: 12 },
  {
    id: 11,
    type: 'paypal',
    imgWrapperClassName: 'paypal-logo',
  },
  { id: 12, type: 'lazypaym', imgWrapperClassName: 'lazypay' },
  { id: 13, type: 'netbanking', imgWrapperClassName: 'md:!p-5' },
];
export const userTabData = [
  {
    key: 0,
    heading: 'New User',
    content: (
      <div className="mt-[32px] md:mt-[36px]  max-w-[500px]">
        <Steps
          steps={[
            {
              id: 1,
              text: (
                <>
                  <a
                    className="text-cf-green font-semibold"
                    href="https://merchant.cashfree.com/merchants/signup?source-action=Shopify%20Page&action=Sign%20Up&button-id=StartNow_Step1NewUser"
                    target="_blank"
                    rel="noreferrer">
                    Signup
                  </a>{' '}
                  for a Cashfree Payments account
                </>
              ),
            },
            {
              id: 2,
              text: <>Upload documents & complete your KYC for activation</>,
            },
            {
              id: 3,
              text: (
                <>
                  <a
                    className="text-cf-green font-semibold"
                    href="https://accounts.shopify.com/store-login"
                    target="_blank"
                    rel="noreferrer">
                    Login
                  </a>{' '}
                  to your Shopify Store, activate Cashfree Payments by entering
                  your registered email id and password that you use to sign in
                  to your Cashfree Payments account and start accepting payments
                </>
              ),
            },
          ]}
        />
      </div>
    ),
  },
  {
    key: 1,
    heading: 'Existing User',
    content: (
      <div className="mt-[32px] md:mt-[36px]  max-w-[510px]">
        <Steps
          steps={[
            {
              id: 1,
              text: (
                <>
                  <a
                    className="text-cf-green font-semibold"
                    href="https://accounts.shopify.com/store-login"
                    target="_blank"
                    rel="noreferrer">
                    Login
                  </a>{' '}
                  to your Shopify Store
                </>
              ),
            },
            {
              id: 2,
              text: (
                <>
                  Activate Cashfree Payments by entering your registered email
                  id and password that you use to sign in to your Cashfree
                  Payments&nbsp;account
                </>
              ),
            },
            {
              id: 3,
              text:
                'Start accepting payments on your shopify store via 120+ payment modes instantly',
            },
          ]}
        />
      </div>
    ),
  },
];
const headingClass = 'mb-4 md:mb-[32px]';
const imageClassName = 'w-full md:pr-[18px]';
const alignStart = true;
const contentClassName = 'md:pl-[19px] mt-0 lg:pt-[60px] md:pt-[30px]';
export const superChargeTabData = [
  {
    key: 0,
    heading: 'Multiple payment modes',
    shouldUnmount: true,
    content: (
      <div className="md:mt-[60px]">
        <TabPanel
          img={{ src: '/img/shopify/tab-1.svg', width: '610', height: '512' }}
          heading="Capture more sales by giving customer payment modes of their choice"
          alignStart={alignStart}
          contentClassName={contentClassName}
          imageClassName={imageClassName}
          headingClass={headingClass}
          body={
            <>
              <div className="max-w-[500px]">
                Go live with <b>120+ payment</b> modes instantly. Let your
                customers pay via any UPI, card, EMI, BNPL, Paytm & other
                wallets, etc. Reach customers across the globe, show items in
                27+ foreign currencies.
              </div>
              <div className="mt-5 mb-[23px] md:mb-[32px] max-w-[500px]">
                Here is more: Access customer payments within <b>15 minutes</b>{' '}
                of fund capture, even on bank holidays with Instant Settlements
                feature.
              </div>
              <PaymentGatewaySlider
                data={logoData}
                className="w-full max-w-[400px] relative shopify"
                settings={{
                  arrows: false,
                  dots: false,
                  variableWidth: true,
                  autoplay: true,
                  infinite: true,
                  centerMode: false,
                  autoplaySpeed: 0,
                  speed: 2000,
                  cssEase: 'linear',
                  pauseOnHover: false,
                }}
              />
            </>
          }
          footer={
            <a
              className="button button-green w-full md:w-auto px-[24.5px] mt-[32px] md:mt-[40px]"
              href="https://www.shopify.com/admin/settings/payments/alternative-providers/1058691">
              Accept Payments on Shopify <span className="chevron" />
            </a>
          }
        />
      </div>
    ),
  },
  {
    key: 1,
    heading: 'COD order refund',
    content: (
      <div className="md:mt-[60px]">
        <TabPanel
          heading="Cash on delivery order refund"
          headingClass={headingClass}
          img={{ src: '/img/shopify/tab-2.svg', width: '610', height: '512' }}
          imageClassName={imageClassName}
          alignStart={alignStart}
          contentClassName={contentClassName}
          body={
            <>
              <div className="max-w-[500px]">
                The biggest problem with COD order refunds is the unavailability
                of payment details. Cashgram makes it easy for customers to
                receive refunds instantly on their preferred destination. Create
                Cashgram link, notify the customers on SMS, Whatsapp or Email.
              </div>
              <div className="mt-5 max-w-[500px]">
                The customers enters his/her bank account/UPI id/Paytm no/debit
                card or amazon wallet id and receive a refund amount to the
                preferred account.
              </div>
            </>
          }
          footer={
            <div className="mt-[32px] md:mt-[40px]">
              <a
                className="button button-green md:px-[40px] w-full md:w-auto"
                href="https://www.cashfree.com/cashgram/">
                Know More <span className="chevron !mb-0" />
              </a>
            </div>
          }
        />
      </div>
    ),
  },
  {
    key: 2,
    heading: 'Increase pre-paid orders',
    content: (
      <div className="md:mt-[60px]">
        <TabPanel
          heading="Increase pre-paid orders & automate COD order refunds"
          headingClass={headingClass}
          img={{ src: '/img/shopify/tab-3.svg', width: '610', height: '512' }}
          imageClassName={imageClassName}
          alignStart={alignStart}
          contentClassName={contentClassName}
          body={
            <>
              <div className="max-w-[500px]">
                <img
                  className="max-w-[294px]"
                  src="/img/shopify/pragcashfree.png"
                  alt="pragme | cashfree"
                  width="294"
                  height="66"
                />
              </div>

              <ul className="p-0 mt-[24px] md:mt-[44px] max-w-[500px] list-none">
                {[
                  {
                    id: 0,
                    text: 'Increase pre-paid orders by upto 35%',
                  },
                  {
                    id: 1,
                    text: 'Save time & effort by automating COD order refunds',
                  },
                  {
                    id: 2,
                    text:
                      'Process instant COD order refunds and delight customers',
                  },
                ].map(({ id, text }) => (
                  <li className="mb-4 last:mb-0 flex" key={id}>
                    <span className="w-5 h-5 flex justify-center items-center rounded-full mt-[2px] mr-[8px] bg-cf-primary flex-none">
                      <Icon
                        name="tick"
                        width="9.21px"
                        height="6.29px"
                        fill="#fff"
                      />
                    </span>
                    <h3 className="font-body !m-0 text-2.5sm md:text-[15px] md:leading-6">
                      {text}
                    </h3>
                  </li>
                ))}
              </ul>
            </>
          }
          footer={
            <div className="mt-[32px] md:mt-[40px]">
              <a
                className="button button-green md:px-[40px] w-full md:w-auto"
                href="https://logisy.tech/partners/cashfree/">
                Know More <span className="chevron !mb-0" />
              </a>
            </div>
          }
        />
      </div>
    ),
  },
  {
    key: 3,
    heading: 'Payment Links',
    content: (
      <div className="md:mt-[60px]">
        <TabPanel
          heading="Get customers to complete payments for abandoned carts"
          headingClass={headingClass}
          img={{ src: '/img/shopify/tab-4.svg', width: '610', height: '512' }}
          imageClassName={imageClassName}
          alignStart={alignStart}
          contentClassName={contentClassName}
          body={
            <div className="max-w-[500px]">
              Generate instant links with the items on your customer&apos;s
              shopping carts and send them personalized communication via email
              or SMS. Also, Payment Links can be used to sell your products on
              multiple social media channels.
            </div>
          }
          footer={
            <div className="mt-[32px] md:mt-[40px]">
              <a
                className="button button-green md:px-[40px] w-full md:w-auto"
                href="https://www.cashfree.com/payment-links/">
                Know More <span className="chevron !mb-0" />
              </a>
            </div>
          }
        />
      </div>
    ),
  },
  {
    key: 4,
    heading: 'On-demand Settlements',
    content: (
      <div className="md:mt-[60px]">
        <TabPanel
          heading="Say bye to T+2 settlement days"
          headingClass={headingClass}
          img={{ src: '/img/shopify/tab-5.svg', width: '610', height: '512' }}
          imageClassName={imageClassName}
          alignStart={alignStart}
          contentClassName={contentClassName}
          body={
            <div className="max-w-[500px]">
              Withdraw only a specific settlement amount to your bank account in
              a single click, whenever you need it the most. Get settlements
              credited to your bank account even on bank holidays and other
              critical times on-demand.
            </div>
          }
          footer={
            <div className="mt-[32px] md:mt-[40px]">
              <a
                className="button button-green md:px-[40px] w-full md:w-auto"
                href="https://www.cashfree.com/instant-settlements/">
                Know More <span className="chevron !mb-0" />
              </a>
            </div>
          }
        />
      </div>
    ),
  },
];
export const shopifyIntegration = [
  {
    id: 0,
    url: 'https://www.cashfree.com/shopify-payment-gateway-india/',
    imgPath: '/img/integrations/shopify.png',
    integrationsName: 'Shopify',
  },
];
export const mobileIntegration = [
  {
    id: 0,
    url: 'https://docs.cashfree.com/docs/android-sdk',
    imgPath: '/img/integrations/android-sdk.png',
    integrationsName: 'Android SDK',
  },
  {
    id: 1,
    url: 'https://docs.cashfree.com/docs/ios-sdk ',
    imgPath: '/img/integrations/ios-sdk.png',
    integrationsName: 'iOS SDK',
  },
  {
    id: 2,
    url: 'https://docs.cashfree.com/docs/react-native-version-2-1-sdk ',
    imgPath: '/img/integrations/react-sdk.svg',
    integrationsName: 'React Native SDK',
  },
  {
    id: 3,
    url: 'https://docs.cashfree.com/docs/flutter-sdk ',
    imgPath: '/img/integrations/flutter-sdk.png',
    integrationsName: 'Flutter SDK',
  },
  {
    id: 4,
    url: 'https://docs.cashfree.com/docs/capacitor-sdk ',
    imgPath: '/img/integrations/capacitor-sdk.png',
    integrationsName: 'Capacitor SDK',
  },
  {
    id: 5,
    url: 'https://docs.cashfree.com/docs/cordova-sdk ',
    imgPath: '/img/integrations/cordova-sdk.svg',
    integrationsName: 'Cordova SDK',
  },
  {
    id: 6,
    url: 'https://docs.cashfree.com/docs/xamarin-android-support-sdk',
    imgPath: '/img/integrations/xamrian-sdk.png',
    integrationsName: 'Xamarin SDK',
  },
];

export const faqData = [
  {
    id: 0,
    q: 'What is Shopify Payment Gateway India?',
    a: (
      <>
        <p>
          Cashfree is Shopify’s leading payment partner in India. We are India’s
          only Shopify payment gateway that provides the fastest payment
          settlement cycle ranging from 24 hours to 48 hours.
        </p>
        <p>
          Cashfree’s plug and play Shopify payment gateway integration is
          code-free and helps you accept payments seamlessly on your Shopify
          store with a high success rate. You can also choose the checkout page
          logo and color to look as part of your own website. With Cashfree
          smart analytics track overall Shopify transactions on your dashboard.
        </p>
      </>
    ),
  },
  {
    id: 1,
    q: 'Which payments modes do you support?',
    a: (
      <>
        <p>
          With Cashfree shopify payment gateway India, you can accept payment
          via NEFT, IMPS, UPI 50+ Netbanking options, all domestic Debit and
          Credit cards (Visa, Master, Maestro, Rupay, Amex), Paytm and 6 other
          popular mobile wallets like Airtel, Mobikwik, Freecharge.
        </p>
        <ul className="list-decimal">
          <li>
            International Payments / Credit cards: Visa, Mastercard, Maestro,
            Rupay and American Express. Option to connect your existing Paypal
            account with Cashfree and offer Paypal as a checkout option. Start
            accepting international payments from across the globe from from day
            one.
          </li>
          <li>
            Multi-currency support: 28+ foreign currencies supported. Additional
            currencies configurable on request.
          </li>
          <li>
            Pay later: Buy now & Pay later option -Ola Credit, ePayLater and
            more.
          </li>
          <li>
            Easy installments: Cardless EMI-InstaCred, Zestmoney and multiple
            banks EMI options.
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 2,
    q: 'Can I sign up as an individual?',
    a: (
      <p>
        Yes. If you are an unregistered business, you can create an account as
        an individual by sharing personal identity and address proof.
      </p>
    ),
  },
  {
    id: 3,
    q: 'How much time does the payment integration take?',
    a: (
      <p>
        Cashfree is a listed Shopify payment partner. Integration is direct and
        takes less than two minutes. Before you start integrating, you need to
        signup on Cashfree to generate your Merchant Id and API Secret key.
      </p>
    ),
  },
  {
    id: 4,
    q: 'What are the shopify payment gateway India charges?',
    a: (
      <p>
        We charge 1.90% per transaction [Lowest in the country]. For a detailed
        breakdown by payment mode, visit our{' '}
        <a
          href="https://www.cashfree.com/payment-gateway-charges/"
          className="external-link"
          target="_blank"
          rel="noreferrer">
          payment gateway charges page
        </a>
        .
      </p>
    ),
  },
  {
    id: 5,
    q:
      'What are the other Cashfree products I can use for my e-commerce store?',
    a: (
      <>
        <p>Cashfree provides complete range of payment solutions.</p>
        <ul className="list-decimal">
          <li>
            Selling on shopify? give 120+ payment options to your customers on
            checkout form when you add Cashfree payment gateway (plug & play
            Shopify API integration) on your Shopify store.
          </li>
          <li>
            Once sold, With Cashfree Cashgram give instant refunds to customers
            even for CODs
          </li>
          <li>
            Getting supplies from multiple vendors? Split commissions with your
            vendors automatically after every purchase with Marketplace
            settlement.
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 6,
    q:
      'Does Cashfree provide payment gateway integration for other online platforms like Woocommerce, Magento etc?',
    a: (
      <p>
        Yes, we have built powerful integrations and APIs for all the major
        online platforms including Magento, Woocommerce, Openkart, Prestashop.{' '}
        <a
          className="external-link"
          href="https://docs.cashfree.com/docs/payment-gateway"
          target="_blank"
          rel="noreferrer">
          See here in documentation.
        </a>
      </p>
    ),
  },
  {
    id: 7,
    q:
      'I sell products to both Indian and international customers, is Cashfree the right payment gateway for me?',
    a: (
      <>
        <p>
          Cashfree&apos;s domestic and international payment gateway is built
          for growing businesses. We support 120+ payment modes both domestic
          and international to help you go global.
        </p>
        <p>
          For domestic payments: We support 50+ netbanking options, Credit Card,
          Debit Card, NEFT, IMPS, Paytm and other wallets, UPI via BHIM UPI ,
          Google Pay, PhonePe etc. We also provide multiple bank EMI and
          cardless EMI options such as Zest Money, along with Buy Now Pay Later
          option (Ola Money Postpaid, ePayLater etc.)
        </p>
        <p>
          For international customers: With support for over 30+ international
          currencies, show your products to your customers in their preferred
          currency. Accept international payments via popular cards including
          Visa, Mastercard, Amex, Diners club and PayPal.
        </p>
        <p>
          PayPal Express Checkout. Cashfree offers direct integration with
          PayPal. Now connect your PayPal business account with Cashfree and
          accept international payments via PayPal using a single Cashfree
          Payment Gateway account for your Shopify store. Limited time offer.{' '}
          <a
            href="https://knowledgebase.cashfree.com/support/solutions/articles/48001176153-paypal-exclusive-offer-paypal-express-checkout-on-cashfree-payment-gateway-"
            className="external-link"
            target="_blank"
            rel="noreferrer">
            Read the offer terms and conditions here
          </a>
          .
        </p>
      </>
    ),
  },
  {
    id: 8,
    q: 'How do I avail PayPal offer?',
    a: (
      <p>
        All exciting and new Cashfree Payment Gateway merchants can avail PayPal
        offer.{' '}
        <a
          href="https://knowledgebase.cashfree.com/support/solutions/articles/48001176153-paypal-exclusive-offer-paypal-express-checkout-on-cashfree-payment-gateway-"
          className="external-link"
          target="_blank"
          rel="noreferrer">
          Read the offer terms and conditions here
        </a>
        .
      </p>
    ),
  },
];

export const otherProducts = [
  {
    id: 0,
    title: 'Payment Gateway',
    body: (
      <>
        Collect payments on your website or app. Make payouts to any
        bank&nbsp;account.
      </>
    ),
    url: 'https://www.cashfree.com/payment-gateway-india/',
    iconname: '/img/icons/payment-gateway.svg',
  },
  {
    id: 1,
    title: 'Payment Links',
    body:
      'No-code payment links to collect payments over WhatsApp, SMS, Facebook, Twitter and other channels.',
    url: 'https://www.cashfree.com/payment-links',
    iconname: '/img/icons/payment-links.svg',
  },
  {
    id: 2,
    title: 'Cashgram',
    body:
      'Payout links to send money without collecting receiver bank account details or UPI details.',
    url: 'https://www.cashfree.com/cashgram/',
    iconname: '/img/icons/cashgram.svg',
  },
  {
    id: 3,
    title: 'Instant Settlements',
    body:
      'Get access to your payment gateway collections within 15 minutes of payment capture, avoid dependency on credit lines.',
    url: 'https://www.cashfree.com/instant-settlements/',
    iconname: '/img/icons/yellow-zap.svg',
  },
];

export const heroLogoData = [
  {
    id: 0,
    src: 'noise-light.svg',
    width: '90',
    height: '26',
    className: 'min-w-[90px] md:max-w-full',
    alt: 'noise',
  },
  {
    id: 1,
    src: 'livspace-light.svg',
    width: '106',
    height: '23',
    className: 'min-w-[106px] md:max-w-[123px]',
    alt: 'livspace',
  },
  {
    id: 2,
    src: 'mybageecha-light.svg',
    width: '51',
    height: '46',
    className: 'min-w-[51px] md:max-w-[123px]',
    alt: 'mybageecha',
  },
  {
    id: 3,
    src: 'bajaao-light.svg',
    width: '90',
    height: '22',
    className: 'min-w-[90px] md:max-w-[88px]',
    alt: 'bajaao',
  },
  {
    id: 4,
    src: 'crossbeats-light.svg',
    width: '110',
    height: '31',
    className: 'min-w-[110px] d:max-w-[113px]',
    alt: 'crossbeats',
  },
  {
    id: 5,
    src: 'kushals-light.png',
    width: '95',
    height: '27',
    className: 'min-w-[95px] max-w-[98px]',
    alt: 'kushals',
  },
  {
    id: 6,
    src: 'headphone-zone.png',
    width: '110',
    height: '30',
    className: 'min-w-[110px] max-w-[110px]',
    alt: 'headphone zone',
  },
];
export const heroSliderSettings = {
  dots: false,
  arrows: false,
  infinite: false,
  autoplay: false,
  autoplaySpeed: 0,
  swipe: false,
  pauseOnHover: false,
  draggable: false,
  centerMode: false,
  speed: 2000,
  slidesToShow: heroLogoData.length,
  variableWidth: true,
  cssEase: 'linear',
  responsive: [
    {
      breakpoint: 768,
      settings: {
        rtl: true,
        draggable: false,
        autoPlay: true,
        autoplay: true,
        speed: 2000,
        swipe: false,
        autoplaySpeed: 2000,
        cssEase: 'linear',
        slidesToShow: heroLogoData.length - 2,
        infinite: true,
        centerMode: false,
        variableWidth: true,
      },
    },
  ],
};
