import React, { useState } from 'react';
import Icon from '../components/Icon';
import Layout from '../components/Layout';
import Scrollspy from '../components/Scrollspy';
import SEO from '../components/SEO';
import Tabs from '../components/Tabs/Tabs';
import PricingAccordion from '../components/common/PricingAccordion/PricingAccordion';
import GetStarted from '../components/GetStarted';
import FAQ from '../components/FAQ';
import OtherProducts from '../components/OtherProducts/OtherProducts';
import StackMerchantSlider from '../components/Sliders/StackMerchantSlider/StackMerchantSlider';
import EnterpriseContainer from '../components/common/EnterprisePricing/EnterpriseContainer';
import HeroLogoSlider from '../components/Sliders/HeroLogoSlider/HeroLogoSlider';
import ModalVideo from '../components/ModalVideo';
import {
  userTabData,
  superChargeTabData,
  heroCheckList,
  shopifyIntegration,
  mobileIntegration,
  faqData,
  otherProducts,
  heroLogoData,
  heroSliderSettings,
} from '../content/shopify';
import '../styles/shopify.scss';

function ShopifyPage({ ...rest }) {
  const [OpenVideoModal, setOpenVideoModal] = useState(false);

  const openVideoModalToggle = () => {
    setOpenVideoModal((state) => !state);
    document.body.classList.toggle('overflow');
  };
  return (
    <Layout
      lightHeader
      {...rest}
      signInLink="https://merchant.cashfree.com/merchants/login?source-action=Shopify%20Page&action=Sign%20In&button-id=SignIn_Navbar"
      createAccountLink="https://merchant.cashfree.com/merchants/signup?source-action=Shopify%20Page&action=Sign%20Up&button-id=StartNow_Navbar">
      <SEO
        title="India’s Best Shopify Payment Gateway | Cashfree Payments"
        description="Shopify Payment Gateway with 120+ payment collection modes, highest success rates, customized checkout experience, fast settlements, 24*7 support, and zero set-up fee."
        url="https://www.cashfree.com/shopify-payment-gateway-india/"
        keywords={[
          'Shopify payment gateway',
          'Shopify payment gateway India',
          'Shopify payments',
          'Shopify India payment gateways',
          'Shopify payments India',
          'best payment gateway for shopify india',
          'Integrate payment gateway in Shopify',
          'Shopify payment gateway integration',
        ]}
      />

      <section className="relative section-padding bg-cf-primary shopify-hero-section overflow-hidden text-white">
        <div className="container relative">
          <div className="flex flex-wrap flex-col lg:flex-row lg:flex-nowrap w-full lg:w-[130%] lg:mb-[20px]">
            <div className="relative z-[1] w-full lg:mt-[70px]">
              <h2 className="text-sm font-semibold text-white pl-5 mb-[10px] uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px] font-body">
                SHOPIFY
              </h2>
              <div className="mb-5 text-xl md:text-2xl font-semibold max-w-[500px] font-heading">
                Best
                <h1 className="inline"> Shopify Payment Gateway</h1>
              </div>
              <p className="text-opacity-80 mb-[30px] max-w-[620px] text-[16px] leading-[24px] md:text-2.5md">
                Go live in 5 minutes with Cashfree Payments on your Shopify
                store. Start accepting payments via 120+ payment options and do
                a lot more.
              </p>

              <ul className="p-0 mt-6 md:mt-8 mb-[30px] md:mb-[25px] list-none max-w-full flex flex-col md:flex-row md:items-center justify-start">
                {heroCheckList.map(({ id, listItem }) => (
                  <li
                    className="mb-[15px] mr-[24px] md:mr-[16px] last:mr-0 flex items-center"
                    key={id}>
                    <span className="w-5 h-5 flex flex-none justify-center items-center rounded-full mr-2 bg-white bg-opacity-25">
                      <Icon
                        name="tick"
                        width="9.21px"
                        height="6.29px"
                        fill="#fff"
                      />
                    </span>
                    <h3 className="font-body !m-0 text-2.5sm md:text-md">
                      {listItem}
                    </h3>
                  </li>
                ))}
              </ul>
              <div className="flex flex-wrap md:flex-nowrap justify-between md:justify-start mb-[16px] md:mb-[48px] lg:mb-10">
                <a
                  className="button button-green w-full md:w-auto md:px-[28px] mb-[16px] md:mb-0 md:mr-[14px]"
                  href="https://www.shopify.com/admin/settings/payments/alternative-providers/1058691">
                  Accept Payments on Shopify <span className="chevron" />
                </a>
              </div>
              <div className="relative md:left-[-13px] h-[48px] flex mx-auto w-max md:w-auto md:mx-0 md:inline-flex flex-row icon-stack items-center mb-4">
                <img
                  className="mr-[8px]"
                  src="/img/shopify/cashfree.png"
                  alt="Cashfree"
                  width="130"
                  height="48"
                />
                <span>+</span>
                <img
                  className="ml-[8px]"
                  src="/img/shopify/shopify.png"
                  alt="Shopify"
                  width="130"
                  height="48"
                />
              </div>
            </div>
            <div className="w-[130%]">
              <video
                autoPlay
                muted
                playsInline
                loop
                className="lg:max-w-[110%] inset-video  relative z-10 chrome-video"
                width="800"
                height="700"
                poster="/img/shopify/placeholder.png">
                <source src="/img/shopify/hero-video.webm" type="video/webm" />
                <source
                  src="/img/shopify/hero-video_H264.mp4"
                  type="video/mp4"
                />
              </video>
              <video
                autoPlay
                muted
                playsInline
                loop
                className="lg:max-w-[110%] inset-video hidden safari-video relative z-10"
                width="800"
                height="700"
                poster="/img/shopify/placeholder.png">
                <source
                  src="/img/shopify/hero-video_H264.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
        </div>
        <section className="logo-container">
          <div className="relative overflow-hidden z-10">
            <HeroLogoSlider
              data={heroLogoData}
              imgUrl="/img"
              className="pl-[10px]"
              itemClassName="mx-[25px] md:mx-[36px] flex items-center align-center h-[48px]"
              settings={heroSliderSettings}
            />
          </div>
        </section>
      </section>
      <Scrollspy
        centeredLinks
        alwaysCenter
        leadContent={
          <div
            className="payout-nav-logo row row-no-margin justify-flex-start"
            style={{ minWidth: '190px' }}>
            <img
              src="/img/icons/shopify-plugin.svg"
              alt="shopify-icon"
              width="32"
              height="32"
              className="w-[20px] md:w-[32px]"
            />
            <span>Shopify Plugin</span>
          </div>
        }>
        <section data-spy-title="Overview" data-spy-id="overview" className="">
          <div className="p-section-3 relative">
            <div className="container">
              <div className="text-shs md:text-shl font-semibold text-cf-hero max-w-[820px] mb-[24px] md:mb-[64px] font-heading">
                Get started with Cashfree Payments on your Shopify store in few
                simple steps
              </div>
              <div className="flex flex-wrap flex-col-reverse md:flex-row">
                <div className="w-full md:w-1/2">
                  <div className="relative">
                    <img
                      className="w-full"
                      src="/img/shopify/yt-placeholder.png"
                      alt=""
                      width="610"
                      height="405"
                    />
                    <button
                      onClick={openVideoModalToggle}
                      className="green-play-button"
                      type="button">
                      <Icon name="play" />
                    </button>
                  </div>
                </div>
                <div className="w-full md:w-1/2 pb-[40px] md:pl-[38px]">
                  <Tabs data={userTabData} />
                </div>
              </div>
            </div>
          </div>
          <div className="bg-cf-light-grey p-section-2 overflow-hidden">
            <div className="container text-center">
              <p className="text-sm font-semibold  text-cf-primary pl-5 mb-[8px] md:mb-[10px] uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px] w-max mx-auto">
                HEAR FROM OUR MERCHANTS
              </p>
              <div className="text-shs md:text-shl font-semibold text-cf-hero max-w-[830px] mb-[24px] md:mb-[32px] font-heading mx-auto">
                10,000+ Indian businesses trust us with their payment needs
              </div>
              <a
                className="button button-green hidden md:inline-block mb-[60px]"
                href="https://www.shopify.com/admin/settings/payments/alternative-providers/1058691">
                Accept Payments on Shopify <span className="chevron" />
              </a>
              <StackMerchantSlider />
            </div>
          </div>
        </section>
        <section
          data-spy-title="Integration"
          data-spy-id="integration"
          className="overflow-hidden">
          <div className="p-section-2 bg-cf-dark shopify-integrations">
            <div className="container">
              <p className="text-sm font-semibold pl-5 mb-[10px] uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px] mx-auto inline-block text-white">
                POWERFUL INTEGRATIONS
              </p>
              <div className="text-shs md:text-shl font-semibold text-white max-w-[550px] mb-[24px] md:mb-[32px] font-heading">
                Quickest and easiest{' '}
                <h2 className="inline">Shopify Payment Gateway integration</h2>
              </div>
              <div className="white max-w-[580px]">
                <p>
                  Connect Cashfree Payments to your Shopify store now.{' '}
                  <span className="md:block">
                    Enjoy quick onboarding and activation. Start transacting
                    within minutes.
                  </span>
                </p>
              </div>
              <div className="row row-no-margin justify-flex-start flex-wrap integrations-block">
                {shopifyIntegration.map(
                  ({ id, imgPath, integrationsName, url }) => (
                    <a
                      href={url}
                      key={id}
                      className="single-integration mct-20">
                      <div className="integration-image">
                        <img
                          src={imgPath}
                          alt={integrationsName}
                          loading="lazy"
                        />
                      </div>
                      <span>{integrationsName}</span>
                    </a>
                  ),
                )}
              </div>
              <h3 className="small-title text-white mt-[40px]">
                Mobile Integrations
              </h3>
              <div className="row row-no-margin justify-flex-start flex-wrap relative z-[1] integrations-block">
                {mobileIntegration.map(
                  ({ id, imgPath, integrationsName, url }) => (
                    <a href={url} key={id} className="single-integration">
                      <div className="integration-image">
                        <img
                          src={imgPath}
                          alt={integrationsName}
                          loading="lazy"
                        />
                      </div>
                      <span>{integrationsName}</span>
                    </a>
                  ),
                )}
              </div>

              <p className="small-title max-w-[450px] font-normal text-cf-cold-purple my-[24px] md:my-[32px] relative z-[1]">
                Add the ready to use plugin to your Shopify store and choose
                from a range of SDKs for seamless mobile checkout.
              </p>
              <a
                href="https://docs.cashfree.com/docs/payment-gateway"
                className="button button-green px-[28px] w-full md:w-auto relative z-[1]">
                View Developer Documentation <span className="chevron" />
              </a>
            </div>
          </div>
          <div className="p-section-3 bg-white">
            <div className="container">
              <div className="text-shs md:text-shl font-semibold max-w-[620px] mb-[24px] md:mb-[32px] font-heading">
                Everything you need to supercharge your Shopify store
              </div>
              <Tabs data={superChargeTabData} dropDown timerAutoPlay />
            </div>
          </div>
        </section>
        <section
          data-spy-title="Pricing"
          data-spy-id="pricing"
          className="p-section-2 bg-cf-light-grey">
          <div className="container">
            <div className="md:text-center mx-auto max-w-[610px]">
              <p className="text-cf-primary text-sm font-semibold pl-5 mb-[10px] uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px] mx-auto inline-block">
                LOWEST PRICING
              </p>
              <div className="text-shs md:text-shl font-semibold mb-6 md:mb-5 font-heading mx-auto">
                Lowest{' '}
                <h2 className="inline">Shopify Payment Gateway Charges</h2>{' '}
                in&nbsp;India
              </div>
              <div className="text-2.5sm md:text-md mb-4 md:mb-8">
                Pay standard Cashfree Payment Gateway Charges. No additional
                charges for payments received for your Shopify store.
              </div>
              <a
                className="button button-green w-full md:w-auto px-[24.5px] mb-8 md:mb-16"
                href="https://www.shopify.com/admin/settings/payments/alternative-providers/1058691">
                Accept Payments on Shopify <span className="chevron" />
              </a>
            </div>
            <EnterpriseContainer
              component={<PricingAccordion />}
              link="https://www.cashfree.com/contact-sales?source-action=Shopify%20Page&action=Contact%20Sales&button-id=ContactSales_Pricing"
            />
          </div>
        </section>
      </Scrollspy>
      <section>
        <div className="container general-faqs">
          <FAQ open="0" data={faqData} />
        </div>
      </section>
      <OtherProducts data={otherProducts} />
      <GetStarted
        startNowLink="https://merchant.cashfree.com/merchants/signup?source-action=Shopify%20Page&action=Sign%20Up&button-id=StartNow_Footer"
        getInTouchLink="https://www.cashfree.com/contact-sales?source-action=Shopify%20Page&action=Contact%20Sales&button-id=ContactSales_Footer"
      />
      <ModalVideo
        OpenVideoModal={OpenVideoModal}
        OpenVideoModalToggle={openVideoModalToggle}
        VideoSrc="https://www.youtube.com/embed/c4PlrdbbaRk?autoplay=1"
      />
    </Layout>
  );
}

export default ShopifyPage;
