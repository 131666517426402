import React from 'react';
import Accordion from '../../Accordion/Accordion';
import PaymentGateway from '../PaymentGateway/PaymentGateway';
import AccordionHead from './AccordionHead';

const paymentClasses = 'w-[48px] md:w-[90px] md:mb-[40px] my-[4px] mx-[16px]';
const textSize = 'text-tiny md:text-2.5sm';
const pricingAccordianData = [
  {
    key: '1',
    heading: 'UPI, Net Banking and Cards',
    content: (
      <div className="md:px-[18px]">
        <div className="text-[16px] leading-6 font-semibold text-cf-primary mb-4 md:mb-[28px]">
          Zero transaction charges
        </div>
        <div className="flex flex-wrap mx-[-16px] mt-[-5px] md:mx-[-21px] md:mt-0 justify-start">
          <PaymentGateway
            type="upi"
            className={paymentClasses}
            textSize={textSize}
          />
          <PaymentGateway
            type="rupay"
            className={paymentClasses}
            textSize={textSize}
          />
        </div>
        <div className="text-[16px] leading-6 font-semibold text-cf-primary mb-4 md:mb-[28px] mt-4 md:mt-[20px]">
          1.90%{' '}
          <span className="inline-block ml-2 line-through text-cf-faded-black">
            2%
          </span>
        </div>
        <div className="grid grid-cols-4 md:flex flex-wrap mx-[-16px] mt-[-5px] md:mx-[-21px] md:mt-0 sm:justify-start">
          <PaymentGateway
            type="phonepe"
            className={paymentClasses}
            textSize={textSize}
          />
          <PaymentGateway
            type="gpayLogo"
            className={paymentClasses}
            textSize={textSize}
          />
          <PaymentGateway
            type="amazonpaylogo"
            padding={8}
            className={paymentClasses}
            textSize={textSize}
          />
          <PaymentGateway
            type="icici"
            className={paymentClasses}
            textSize={textSize}
          />
          <PaymentGateway
            type="visa"
            className={paymentClasses}
            textSize={textSize}
          />
          <PaymentGateway
            type="mastercard"
            className={paymentClasses}
            textSize={textSize}
          />
          <PaymentGateway
            type="maestro"
            className={paymentClasses}
            textSize={textSize}
          />
          <PaymentGateway
            type="netbanking"
            className={paymentClasses}
            textSize={textSize}
          />
        </div>
      </div>
    ),
  },
  {
    key: '2',
    heading: (
      <AccordionHead heading="Paylater & Cardless EMI" subHeading="2.5%" />
    ),
    content: (
      <div className="md:px-[18px]">
        <div className="grid grid-cols-4 md:flex flex-wrap mx-[-16px] mt-[-10px] md:mx-[-21px] md:mt-0 sm:justify-start max-w-[400px] md:max-w-full">
          <PaymentGateway
            type="zestmoneyPng"
            className={paymentClasses}
            textSize={textSize}
          />
          <PaymentGateway
            type="olamoneym"
            className={paymentClasses}
            textSize={textSize}
          />
          <PaymentGateway
            type="epaylaterw"
            className={paymentClasses}
            textSize={textSize}
          />
          <PaymentGateway
            type="flexmoney"
            className={paymentClasses}
            textSize={textSize}
          />
          <PaymentGateway
            type="icici"
            className={paymentClasses}
            textSize={textSize}
          />
          <PaymentGateway
            type="kotak"
            className={paymentClasses}
            textSize={textSize}
          />
          <PaymentGateway
            type="hdfc"
            className={paymentClasses}
            textSize={textSize}
          />
        </div>
      </div>
    ),
  },
  {
    key: '3',
    heading: (
      <AccordionHead heading="International Cards" subHeading="3.5% + ₹7" />
    ),
    content: (
      <div className="md:px-[18px]">
        <div className="flex flex-wrap mx-[-16px] mt-[-10px] md:mx-[-21px] md:mt-0 ">
          <PaymentGateway
            type="visa"
            className={paymentClasses}
            textSize={textSize}
          />
          <PaymentGateway
            type="mastercard"
            className={paymentClasses}
            textSize={textSize}
          />
        </div>
      </div>
    ),
  },
  {
    key: '4',
    heading: (
      <AccordionHead
        heading="Paypal & Paytm"
        subHeading="Zero Cashfree Charges"
      />
    ),
    content: (
      <div className="md:px-[18px]">
        <div className="flex flex-wrap mx-[-16px] mt-[-10px] md:mx-[-21px] md:mt-0">
          <PaymentGateway
            type="paypal"
            className={paymentClasses}
            textSize={textSize}
            imgWrapperClassName="paypal-logo-payment-gateway"
          />
          <PaymentGateway
            padding={6}
            type="paytm"
            className={paymentClasses}
            textSize={textSize}
          />
        </div>
        <p className="text-cf-faded-black text-[11px] font-normal opacity-40">
          Note: Only standard PayPal and Paytm respective payment gateway
          charges will apply.
        </p>
      </div>
    ),
  },
  {
    key: '5',
    heading: <AccordionHead heading="Other Cards" subHeading="2.95%" />,
    content: (
      <div className="md:px-[18px]">
        <div className="flex flex-wrap mx-[-16px] mt-[-10px] md:mx-[-21px] md:mt-0">
          <PaymentGateway
            type="dinersclub"
            className={paymentClasses}
            textSize={textSize}
          />
          <PaymentGateway
            type="amexFull"
            className={paymentClasses}
            padding={5}
            textSize={textSize}
          />
        </div>
      </div>
    ),
  },
];
function PricingAccordion() {
  return <Accordion data={pricingAccordianData} type="box" />;
}

export default PricingAccordion;
