import React from 'react';
import PropTypes from 'prop-types';

function Steps({ steps, maxWidth, stepClass, fontSizeClass }) {
  return (
    <ul className={`${maxWidth}`}>
      {(steps || []).map(({ id, text }) => (
        <li key={id} className={stepClass}>
          <div className="md:text-3md font-semibold font-body text-cf-hero mb-[8px]">
            Step {id}
          </div>
          <div className={fontSizeClass}>{text}</div>
        </li>
      ))}
    </ul>
  );
}
Steps.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      text: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    }),
  ),
  maxWidth: PropTypes.string,
  stepClass: PropTypes.string,
  fontSizeClass: PropTypes.string,
};
Steps.defaultProps = {
  steps: [],
  maxWidth: 'max-w-auto',
  stepClass: 'mt-[24px] md:mt-[36px]',
  fontSizeClass: 'text-2.5sm md:text-md',
};
export default Steps;
