import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import './ModalVideo.scss';

function ModalVideo({ OpenVideoModal, OpenVideoModalToggle, VideoSrc }) {
  return (
    <div
      className={`row row-no-margin modal-video-main ${
        OpenVideoModal ? 'show-modal' : ''
      }`}>
      <div className="modal-video">
        <button
          type="button"
          onClick={OpenVideoModalToggle}
          className="close-video-modal">
          <Icon name="close" className="mx-auto" />
        </button>
        <iframe
          src={OpenVideoModal ? VideoSrc : ''}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    </div>
  );
}

ModalVideo.propTypes = {
  OpenVideoModal: PropTypes.bool.isRequired,
  OpenVideoModalToggle: PropTypes.func.isRequired,
  VideoSrc: PropTypes.string,
};

ModalVideo.defaultProps = {
  VideoSrc: 'https://www.youtube.com/embed/SA4H5UAhzCQ',
};

export default ModalVideo;
