import React, { useState, useRef } from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import SliderButton from '../../SliderEasySplit/SliderButton';
import './StackMerchantSlider.scss';

const merchants = [
  {
    id: 0,
    image: '/img/shopify/mansi-saxena.png',
    companyLogo: {
      src: '/img/shopify/noise-logo.png',
      width: '108',
      height: '44',
      alt: 'Noise',
    },
    name: 'Mansi Saxena',
    designation: 'Manager - Business Operations, gonoise.com',
    quote:
      'Cashfree Payments give us next day settlements. For a startup like us it means money reaches our account without being blocked anywhere.',
  },
  {
    id: 1,
    image: '/img/shopify/ankit.png',
    companyLogo: {
      src: '/img/shopify/kushals-logo.png',
      width: '108',
      height: '44',
      alt: 'Kushals',
    },
    name: 'Ankit Gulechha',
    designation: 'Marketing Head, Kushal’s Fashion Jewellery',
    quote:
      'We do international sales as well and Paypal is a preferred mode abroad. When we partnered with Cashfree Payments, we had to just share our existing Paypal account credentials and the integrations happened in no time. Happy with the integration process.',
  },
];

function StackMerchantSlider({ data, settings }) {
  const [activeSlide, setActiveSlide] = useState(0);
  const slider = useRef(null);
  const onPrev = () => {
    slider?.current?.slickPrev();
  };
  return (
    <div className="stack-merchant-slider min-h-[400px] flex flex-wrap text-left relative">
      <SliderButton
        className={`absolute hidden md:flex top-1/2 -left-6 `}
        // ${(activeSlide === 0 && 'slick-disabled') || ''}
        // disabled={activeSlide === 0}
        onClick={onPrev}
      />
      <div className="w-full md:w-1/2 relative hidden md:flex min-h-[300px] md:min-h-[390px] overflow-hidden">
        {data.map((d, index) => (
          <img
            key={d.id}
            src={d.image}
            alt={d.name}
            className={`absolute left-0 top-0 object-cover w-full h-full opacity-0 transition-opacity ${
              activeSlide === index ? 'opacity-100' : ''
            }`}
          />
        ))}
      </div>
      <div className="w-full md:w-1/2 md:px-[24px] xl:pl-[38px] relative self-center pt-4 pb-12 md:py-0">
        <Slider
          {...settings}
          beforeChange={(_, nextIndex) => {
            setActiveSlide(nextIndex);
          }}
          ref={slider}>
          {data.map((d) => (
            <div key={d.id} className="item">
              <div className="flex flex-col">
                <div className="block md:hidden overflow-hidden mb-4">
                  <img
                    key={d.id}
                    src={d.image}
                    alt={d.name}
                    className="object-cover w-full h-[300px]"
                  />
                </div>
                <div className="text-md md:text-3md mb-5 md:mb-10 font-medium max-w-[534px] md:pr-[20px]">
                  {d.quote}
                </div>
                <img alt="" {...d.companyLogo} className="mb-2.5" />
                <div className="text-md">
                  <div>{d.name}</div>
                  <div>{d.designation}</div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

StackMerchantSlider.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      image: PropTypes.string,
      companyLogo: PropTypes.shape({
        src: PropTypes.string,
        width: PropTypes.string,
        height: PropTypes.string,
        alt: PropTypes.string,
      }),
      name: PropTypes.string,
      designation: PropTypes.string,
      quote: PropTypes.string,
    }),
  ),
  settings: PropTypes.shape({}),
};

StackMerchantSlider.defaultProps = {
  data: merchants,
  settings: {
    dots: true,
    arrows: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SliderButton left={false} />,
    prevArrow: <SliderButton />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: true,
        },
      },
    ],
  },
};

export default StackMerchantSlider;
